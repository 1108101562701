import React from "react"
import tw, { styled } from "twin.macro"
import { navigate } from "gatsby"

const SearchContainer = styled.div`
  ${tw`relative`}
`

const SearchIconContainer = styled.div`
  ${tw`absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none`}
`

const Svg = styled.img`
  ${tw`w-5 h-5 text-gray-500 dark:text-gray-400`}
`

const SearchInput = styled.input`
  ${tw`
    block w-full p-3 mr-10 text-sm
    text-gray-900 
    border border-gray-300 
    rounded-lg bg-gray-50 
    focus:outline
    focus:ring-blue-500 
    focus:outline-blue-500`}
`

const SearchBar = ({ searchQuery, setSearchQuery, location }) => (
  <form
    action="/"
    method="get"
    autoComplete="off"
    onSubmit={e => {
      e.preventDefault()
      navigate(`/search/?s=${encodeURIComponent(searchQuery)}`)
    }}
  >
    <SearchContainer>
      <SearchInput
        value={searchQuery}
        onInput={e => setSearchQuery(e.target.value)}
        type="text"
        id="search-input"
        placeholder="Search..."
        name="s"
      />
      <SearchIconContainer>
        <Svg src="/img/svg/search.svg" alt="Search" />
      </SearchIconContainer>
    </SearchContainer>
  </form>
)

export default SearchBar
